// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import VideoGallery from './VideoGallery';
import PhotoGallery from './PhotoGallery';
import Upload from './Upload';
import Layout from './Layout';

const App = () => {
  // State to store uploaded files
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/video-gallery" element={<VideoGallery videos={videos} />} /> */}
          {/* <Route path="/photo-gallery" element={<PhotoGallery photos={photos} />} /> */}
          {/* <Route path="/upload" element={<Upload setPhotos={setPhotos} setVideos={setVideos} />} /> */}
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;