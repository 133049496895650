// HomePage.js
import React, { useEffect, useState, useRef } from 'react';
import './HomePage.css';

const HomePage = () => {
  const [catImage, setCatImage] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const hasFetched = useRef(false);

  // Function to fetch a new cat image
  const fetchCatImage = () => {
    setIsLoaded(false); // Reset loading state for blur effect
    setError(null);

    fetch('https://api.thecatapi.com/v1/images/search')
      .then((response) => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .then((data) => {
        // Delay updating the image slightly to ensure the state resets.
        setCatImage(data[0].url);
      })
      .catch((error) => {
        console.error('Error fetching cat image:', error);
        setError('Failed to load cat image');
      });
  };

  // Fetch the initial image on component mount
  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;
    fetchCatImage();
  }, []);

  return (
    <div className="home-page">
      <div className="container">
        <h1>Welcome to dimonchi.dev</h1>
        {error ? (
          <div className="alert">{error}</div>
        ) : (
          <div className="image-wrapper">
            <img
              key={catImage} // Use the image URL as the key to force re-render
              className={`cat-image ${isLoaded ? 'loaded' : 'loading'}`}
              src={catImage}
              alt="Cat"
              onLoad={() => setIsLoaded(true)}
              onClick={fetchCatImage} // Fetch new image when clicked
              style={{ cursor: 'pointer' }} // Indicate that the image is clickable
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
