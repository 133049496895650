// Layout.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

const Layout = ({ children }) => {
  return (
    <div className="home-page">
      <div className="navbar">
        <img src="/logo512.png" alt="Website Logo" className="navbar-logo" />
        <Link to="/">Home</Link>
        {/* <Link to="/video-gallery">Video Gallery</Link> */}
        {/* <Link to="/photo-gallery">Photo Gallery</Link> */}
        {/* <Link to="/upload">Upload</Link> */}
      </div>
      <div className="container">
        {children}
      </div>
    </div>
  );
};

export default Layout;